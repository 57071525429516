body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  margin: 5px;
  padding: 5px;
}

label {
  font-size: 20px;
}

input {
  font-size: 18px;
  margin-left: 5px;
}



.main_layout {
  text-align: center;
  /* background-image: linear-gradient(to right top, #15123f, #1a285a, #1c3f75, #1a5790, #1470aa, #0384bb, #0098cb, #00acd9, #00bede, #00cfdd, #00e0d6, #3eefca); */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.login-button {
  padding: 1.3em 3em;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  margin: 10px;
}

.login-button:hover {
  background-color: #8B27B0;
  box-shadow: 0px 15px 20px rgba(106, 27, 154, 0.5);
  color: #fff;
  transform: translateY(-7px);
}

.login-button:active {
  transform: translateY(-1px);
}

.scanner-label {
  font-size: 20vh;
  margin: 10px;
  text-shadow: 10px 10px 0px #8B27B0;
}


.centered-container {
  display: table;
  width: 100%;
  height: 100%;
}
