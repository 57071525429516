body {
    background: #000000;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.particle {
	position: absolute;
 	border-radius: 50%;
}


.particle-container {
    position: relative;
    height: 100vh;
    width: 100vw;
}

@for $i from 1 through 60 {
	@keyframes particle-animation-#{$i} {
		100% {
			transform: translate3d((random(90) * 1vw), (random(90) * 2vh), (random(100) * 2px));
		}
	}
	
	.particle:nth-child(#{$i}){
		animation: particle-animation-#{$i} 60s infinite;
		$size: random(130) + 20 + px;
		opacity: random(100)/100;
		height: $size;
		width: $size;
		animation-delay: -$i * .2s;
		transform: translate3d((random(90) * 1vw), (random(90) * 1vh), (random(100) * 1px));
		background: rgba(106, 27, 154);
    filter: blur(5px);
        z-index: -1;
	}
}